import { ApiResponseDateTime, FirestoreTimestamp } from '#/Utils/types';
import { FlagObject } from '#/api/monitoring';
import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';
import { NpdbReportType, Provider } from '#/services/generated-api';
import {
  CredentialingStatusType,
  CredentialingTimelineFromApi,
  CredentialingTimeline,
} from './credentialing.types';
import { HomeAddressValue, ProfessionalID } from './licensing-workflows.types';
import { SingleOrgEntityResponse } from './org-entity.types';
import {
  HsdSpecialty,
  Specialties,
  SpecialtiesAPIResponse,
} from './specialties.types';
import { FileTypeConfigOverridesType } from './organization.types';
import { CollapsedDataGridProps } from '#/components/CollapseTable';

export type ErrorResponseItem = {
  reason: string;
  providerIds: string[];
  providerNames: string[];
};

export type ErrorResponseFromServer = {
  reason: string;
  providerId: string;
  providerName: string;
}[];

export type ProviderStatus =
  | 'New'
  | 'In Progress'
  | 'Active'
  | 'Denied'
  | 'Terminated';

export interface AddNewProviderPayload {
  nextCredentialingDate?: Date;
  lastCredentialedDate?: Date;
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
  providerType: string;
  caqhProviderId: string;
  allProviderStates: string[];
  gender?: string;
  dateOfBirth?: Date;
  primaryEmail?: string;
  groupName?: string;
  groupNPI?: string;
  groupTIN?: string;
  locationName?: string;
  externalGroupId?: string;
  initialCredentialingDate?: Date;
  practitionerSpecialties?: Specialties[] | null;
  practitionerPrimarySpecialty?: Specialties | null;
  practitionerHsdSpecialty?: HsdSpecialty | null;
  isPCP?: boolean;
  isSpecialist?: boolean;
}

export interface AddProviderSpecialtyPayload {
  providerId: string;
  specialty: Specialties;
}

export interface UpdateProviderSpecialtyPayload
  extends AddProviderSpecialtyPayload {
  specialtyId: string;
}

export interface UpdateProviderSpecialtiesPayload {
  providerId: string;
  practitionerSpecialties: Specialties[] | null;
  practitionerPrimarySpecialty?: Specialties | null;
  practitionerHsdSpecialty?: HsdSpecialty | null;
}

export interface ProviderFileData {
  file: File;
  uploadedFileColumns: string[];
  uploadedFileRows: string[];
  columnMatchMatrix: {
    providerLastName: string;
    providerFirstName: string;
    providerMiddleName: string;
    providerDegree: string;
    providerNpi: string;
    allProviderStates: string;
    providerCaqh: string;
    providerId: string;
  };
}

export interface EmailAddress {
  type: string;
  email: string;
}

export interface ContactNumber {
  type: string;
  providedContactNumber: string;
  sanitizedContactNumber?: string;
  ext?: string;
}

export interface ProviderDetailsAPIResponse {
  orgEntities?: any;
  dynamicFields?: any;
  caqhProviderId: string;
  caqhLastAttestationDate?: string;
  caqhLastAttestationDateUpdatedBy?: string;
  caqhLastAttestationDateUpdatedByFullname?: string;
  caqhLastAttestationDateUpdatedAt?: ApiResponseDateTime;
  practitionerStatus?: string;
  primaryEmail: string;
  contactNumber?: string;
  caqhPrimaryEmail?: string;
  homeAddress?: HomeAddressValue;
  middleName: string;
  fileType: string;
  providerType: string;
  firstName: string;
  lastName: string;
  ssn?: string;
  gender?: string;
  otherLanguages?: { name: string }[];
  ethnicity?: string;
  salutation?: string;
  suffix?: string;
  degree?: string;
  emailAddresses?: EmailAddress[];
  contactNumbers?: ContactNumber[];
  acceptsNewPatients?: boolean;
  isPCP?: boolean;
  isSpecialist?: boolean;
  flagsObjects: FlagObject[];
  flags: Record<string, unknown>;
  dateOfBirth: ApiResponseDateTime;
  lastFetchDate: ApiResponseDateTime;
  updatedAt: ApiResponseDateTime;

  lastFetchStatus: string;
  currentFlags?: Record<string, Flag>;
  id: string;
  providerId: string;
  providerStatus: ProviderStatus;
  npi: string;
  psvFile: string;
  updatedBy: string;
  businessPurpose: BusinessPurpose;
  workflowStatus?: string;
  credentialingStatus?: CredentialingStatusType;
  credentialingWorkflow: {
    isCompleted: boolean;
    onStep: {
      title: CredentialingStatusType;
    };
    completedAt: FirestoreTimestamp;
    outreachReasons: string[];
    assignment: {
      assignedToFirstName: string;
      assignedToLastName: string;
      assignedToId: string;
      assignedByFirstName: string;
      assignedByLastName: string;
      assignedById: string;
      assignedOn: FirestoreTimestamp;
      lastActivity: FirestoreTimestamp;
    };
    credentialingCycle: string;
    createdAt: FirestoreTimestamp;
  };
  states?: string[];
  nppes: nppesData[];
  fileTypeScores?: any;
  verifications: PipeLineVerifications;
  assignedStates?: string[];
  hasEditProvider: boolean;
  'dea-data': ProviderDEAData[];
  'state-licenses': ProviderStateLicense[];
  'professional-ids': ProfessionalID[];
  'licensure-actions': ProviderLicensureActions[];
  sanctions: ProviderSanctions[];
  'board-certifications': ProviderBoardCertifications[];
  'malpractice-insurances': ProviderLiabilityInsurance[];
  'performance-data': ProviderPerfData[];
  'npdb-data': ProviderNatDataBanks[];
  npdbProviderInfo: NpdbProviderInfo;
  'application-verifications': ProviderAppVerifications[];
  'education-trainings': ProviderEducationTrainings[];
  'hospital-affiliation': ProviderHospitalAffiliations[];
  'data-sources': ErrorsWarningsData[];
  credentialingWorkflowTimeline: CredentialingTimelineFromApi;
  'error-warnings': ErrorsWarningsData[];
  'supporting-documents': SupportingDocumentsData[];
  'caqh-data'?: CaqhData[];
  orgEntityDetails: SingleOrgEntityResponse[];
  defaultFileTypeConfigOverrides?: FileTypeConfigOverridesType;
  fileTypeAutoCalculatedValue?: string;
  fileTypeManuallyOverridden?: boolean;
  externalId?: string;
  specialties?: SpecialtiesAPIResponse[];
  practitionerPrimarySpecialty?: Specialties | null;
  practitionerHsdSpecialty?: HsdSpecialty | null;
}

export interface ErrorsWarningsData {
  id: string;
  createdAt: Date | FirestoreTimestamp;
  errorObject?: { description: string; type: string }[];
  name: string;
  success: boolean;
}

export interface ProviderEducationTrainings extends ProviderSubcollection {
  id: string;
  source: string;
  institution?: string;
  degree?: string;
  specialty?: string;
  subSpecialty?: string;
  type?: string;
  document: string;
  startDate?: ApiResponseDateTime;
  endDate?: ApiResponseDateTime;
  dateOfScreenshot: ApiResponseDateTime;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
  attachmentCount?: number;
}

export interface ProviderHospitalAffiliations extends ProviderSubcollection {
  id: string;
  name: string;
  type: string;
  startDate: string | Date;
  source?: string;
  specialty?: string;
  endDate?: string | Date;
  attachmentCount?: number;
  state?: string;
}

export interface ProviderNatDataBanks extends ProviderSubcollection {
  id: string;
  typeOfAction: string;
  reportType: string;
  lastReportDate: ApiResponseDateTime;
  reportDate: ApiResponseDateTime;
  reportData?: ProviderNpdbReportData;
  enrollmentStatus?: ProviderNpdbEnrollmentStatus;
  processDate: ApiResponseDateTime;
  document: string;
  source: string;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
}

export interface NpdbProviderInfo {
  databankSubjectID: string;
  enrollmentRenewalDate: Date | FirestoreTimestamp;
  enrollmentStartDate: Date | FirestoreTimestamp;
}

export interface ProviderLiabilityInsurance extends ProviderSubcollection {
  expirationDate: ApiResponseDateTime;
  id: string;
  policyNumber: string;
  source: string;
  status: string;
  occurrenceCoverageAmount?: number;
  aggregateCoverageAmount?: number;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
  attachmentCount?: number;
}

export interface ProviderPerfData extends ProviderSubcollection {
  complaints: number;
  clinical: number;
  service: number;
  benefits: number;
  claims: number;
  accessIssues: number;
  id: string;
  source: string;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
}

export interface ProviderBoardCertifications extends ProviderSubcollection {
  id: string;
  expirationDate: ApiResponseDateTime;
  issueDate: ApiResponseDateTime;
  subSpecialty: string;
  durationType: string;
  status: string;
  specialty: string;
  document: string;
  moc: string;
  source: string;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
  attachmentCount?: number;
}

export interface ProviderSanctions extends ProviderSubcollection {
  reportDate: ApiResponseDateTime;
  typeOfAction: string;
  sanctionType: string;
  id: string;
  source: string;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
}

export interface ProviderLicensureActions extends ProviderSubcollection {
  id: string;
  lastActionDate: ApiResponseDateTime;
  reportDate: ApiResponseDateTime;
  state: string;
  details: string;
  actionType: string;
  source: string;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
}

export interface ProviderDEAData extends ProviderSubcollection {
  id: string;
  state: string;
  deaNumber: string;
  expirationDate: ApiResponseDateTime;
  providerType: string;
  type: string;
  source: string;
  status?: 'Active' | 'Inactive';
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
  attachmentCount?: number;
  psvUrl?: string;
}

export interface ProviderStateLicense extends ProviderSubcollection {
  state: string;
  expirationDate: ApiResponseDateTime;
  issueDate: ApiResponseDateTime;
  type: string;
  licenseStatus: string;
  licenseNumber: string;
  licenseType?: string;
  source: string;
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
  id: string;
  attachmentCount?: number;
  dataLastAcquiredDate?: ApiResponseDateTime;
  psvUrl?: string;
}

export interface PipeLineVerifications {
  npiValidated?: boolean;
  caqhRostered?: boolean;
  npdbEnrolled?: boolean;
}

export interface nppesData {
  flag: Flag;
  firstName: string;
  lastName: string;
  middleName: string;
}

export interface CaqhData {
  createdAt: ApiResponseDateTime;
  id: string;
  practitionerDisclosure: PractitionerDisclosure[];
}

export interface ProviderSubcollection {
  verifiedOn?: ApiResponseDateTime;
  updatedAt?: ApiResponseDateTime;
  verifiedAt?: ApiResponseDateTime;
  verifiedBy?: string;
  verifiedByFullName?: string;
  updatedByFullName?: string;
}

export interface ProviderAppVerifications extends ProviderSubcollection {
  verificationType: string;
  explanation: string;
  id: string;
  source: string;
  disclosureQuestions: PractitionerDisclosure[];
  admittingPrivileges: AdmittingPrivilege[];
  workHistory: WorkHistory[];
  flag?: Flag;
  flags?: Record<string, unknown>; // TODO: Refactor the type
}

export interface OldFlag {
  flagType: string;
  active: boolean;
  description: string;
}

export interface PossibleItemFlags {
  flags?: Record<string, Flag>;
  flag?: OldFlag;
}

export interface Flag {
  id: string;
  flagType: string;
  active: boolean;
  read?: boolean;
  synced?: boolean;
  description: string;
  subCollectionName?: string;
  modifiedReadAt?: Date;
  modifiedReadBy?: string;
  modifiedReadByFullName?: string;
  modifiedActiveAt?: Date;
  modifiedActiveBy?: string;
  modifiedActiveByFullName?: string;
  showMoreActionsAsFlagHasIdAndWorkflowInImportedOrInprogressStatus?: boolean;
}

export interface FlagApiResponse {
  id: string;
  flagType: string;
  active: boolean;
  read?: boolean;
  synced?: boolean;
  description: string;
  subCollectionName?: string;
  modifiedReadAt?: FirestoreTimestamp;
  modifiedReadBy?: string;
  modifiedReadByFullName?: string;
  modifiedActiveAt?: FirestoreTimestamp;
  modifiedActiveBy?: string;
  modifiedActiveByFullName?: string;
  showMoreActionsAsFlagHasIdAndWorkflowInImportedOrInprogressStatus?: boolean;
}

export interface SupportingDocumentsData {
  createdByName?: string;
  createdAt: ApiResponseDateTime;
  createdBy: string;
  fileType: string;
  fileUrl: string;
  signedFileUrl?: string;
  gsUrl: string;
  originalFilename: string;
  isFileErrored?: boolean;
  id: string;
  flag?: {
    flagType: string;
    active: boolean;
  };
  subCollectionReference: string;
  subCollectionDocumentId: string;
  subCollectionName: string;
  provider?: Provider;
  orgEntityName?: string;
  orgEntityId?: string;
  errors?: string;
}

export interface ProviderAttachment {
  createdAt?: ApiResponseDateTime;
  createdBy?: string;
  createdByName?: string;
  orgId?: string;
  id: string;
  originalFilename?: string;
  reportType?: string;
  subCollectionDocumentId?: string;
}

export interface PractitionerDisclosure {
  disclosureAnswerDetail: string;
  practitionerMedicareMedicaidSanctions: any[];
  practitionerMalpracticeClaimHistory: any[];
  practitionerAdverseAction: any[];
  disclosureQuestionDetail: string;
  practitionerProfessionalLiabilityAction: any[];
  disclosureAnswerFlag: boolean;
  disclosureDate: null;
}

export interface AdmittingPrivilege {
  address?: HomeAddressValue;
  admittingPrivilegeStatus?: 'ACTIVE' | 'INACTIVE';
  department?: string;
  startDate?: Date;
  endDate?: Date;
  institution?: string;
  phoneNumber?: string;
}

export interface WorkHistory {
  explanation: string;
  gapEndDate?: Date;
  gapStartDate?: Date;
}

export interface ProviderNpdbEnrollmentStatus {
  status: string;
  statusCode: string;
  startDate: string;
  endDate: string;
}

export interface ProviderNpdbReportData {
  type: string;
  totalPaymentForThisPractitioner?: string;
  dateOfSettlement?: Date;
  judgementOrSettlementDesc?: string;
  medicalConditionDesc?: string;
  procedureDesc?: string;
  allegationsDesc?: string;
  deceased?: string;
  providerAttestation?: string;
  amountOfPractitioner?: string;
  amount?: string;
}

export type ProviderSectionUpdatePayload =
  | ProviderDEAData
  | ProviderStateLicense
  | ProviderLicensureActions
  | ProviderSanctions
  | ProviderBoardCertifications
  | ProviderLiabilityInsurance
  | ProviderPerfData
  | ProviderNatDataBankSection
  | ProviderAppVerificationsSection
  | ProviderEduTrainData
  | ProviderHospitalAffiliationData;

export interface ProviderNatDataBankSection extends ProviderSection {
  id: string;
  typeOfAction: string;
  lastReportDate?: Date | FirestoreTimestamp;
  processDate?: Date | FirestoreTimestamp;
  reportDate?: Date;
  reportData?: ProviderNpdbReportData;
  enrollmentStatus?: ProviderNpdbEnrollmentStatus;
  reportType: NpdbReportType;
  displayStatus: string;
  document: string;
  totalPaymentForThisPractitioner?: string;
  deceased?: string;
  source?: string;
  flag?: object;
}

export interface ProviderAppVerificationsSection extends ProviderSection {
  id: string;
  verificationType: string;
  explanation: string;
}

export interface ProviderSection {
  verifiedAt?: Date;
  verifiedBy?: string;
  verifiedByFullName?: string;
}

export enum EnumerationType {
  PROVIDER = 'NPI-1',
  FACILITY = 'NPI-2',
}

export interface NppesData {
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  prefix?: string;
  credential?: string;
  enumerationType?: EnumerationType;
  status?: string;
  active?: boolean;
}

export interface ProviderDetailOverviewSection {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  salutation?: string;
  suffix?: string;
  degree?: string;
  acceptsNewPatients?: boolean;
  isPCP?: boolean;
  isSpecialist?: boolean;
  ssn?: string;
  gender?: string;
  otherLanguages?: string[];
  ethnicity?: string;
  dateOfBirth?: Date | FirestoreTimestamp;
  lastFetchStatus: string;
  lastFetchDate: Date | null;
  npi: string;
  providerType: string;
  providerStatus: ProviderStatus;
  fileType: string;
  caqhProviderId: string;
  caqhLastAttestationDate?: string;
  caqhLastAttestationDateUpdatedAt?: Date;
  caqhLastAttestationDateUpdatedBy?: string;
  caqhLastAttestationDateUpdatedByFullname?: string;
  practitionerStatus?: string;
  primaryEmail: string;
  caqhPrimaryEmail?: string;
  homeAddress?: HomeAddressValue;
  credentialingStatus: string;
  assignedStates?: string[];
  states?: string[];
  flags?: Flag;
  verifications: PipeLineVerifications;
  credentialingCycle?: string;
  fileTypeScores?: any;
  externalId?: string;
  practitionerSpecialties?: Specialties[] | null;
  nppes?: NppesData[];
  orgEntities?: CollapsedDataGridProps;
}

export interface ProviderDetailsLicenseSection extends ProviderSection {
  id: string;
  state: string;
  expirationDate?: Date | FirestoreTimestamp;
  issueDate?: Date | FirestoreTimestamp;
  type: string;
  licenseStatus: string;
  licenseNumber: string;
  licenseType?: string;
  attachmentCount?: number;
}

export interface ProviderDetailDeaSection extends ProviderSection {
  id: string;
  state: string;
  deaNumber: string;
  expirationDate?: Date | FirestoreTimestamp;
  providerType: string;
  attachmentCount?: number;
}

export interface ProviderDetailBoardCertificationsSection
  extends ProviderSection {
  id: string;
  expirationDate?: Date | FirestoreTimestamp;
  issueDate?: Date | FirestoreTimestamp;
  subSpecialty: string;
  specialty: string;
  document: string;
  moc: string;
  source: string;
  attachmentCount?: number;
}

export interface ProviderDetailLicensureActionsSection extends ProviderSection {
  id: string;
  lastActionDate?: Date | FirestoreTimestamp;
  reportDate?: Date | FirestoreTimestamp;
  actionType: string;
}

export interface ProviderDetailSanctionsSection extends ProviderSection {
  id: string;
  reportDate?: Date | FirestoreTimestamp;
  typeOfAction: string;
  sanctionType: string;
}

export interface ProviderDetailMalpracticeInsuranceSection
  extends ProviderSection {
  expirationDate?: Date | FirestoreTimestamp;
  id: string;
  policyNumber: string;
  source: string;
  status: string;
  attachmentCount?: number;
}

export interface ProviderDetailPerfIndicatorSection extends ProviderSection {
  complaints: number;
  clinical: number;
  service: number;
  benefits: number;
  claims: number;
  accessIssues: number;
  id: string;
}

export interface ProviderEduTrainData extends ProviderSection {
  id: string;
  source: string;
  document: string;
  startDate?: string | Date;
  endDate?: string | Date;
  dateOfScreenshot?: Date | FirestoreTimestamp;
  attachmentCount?: number;
}

export interface ProviderHospitalAffiliationData extends ProviderSection {
  id: string;
  name: string;
  type: string;
  startDate?: string | Date;
  source?: string;
  specialty?: string;
  endDate?: string | Date;
  attachmentCount?: number;
  state?: string;
}

export interface SupportingDocumentsSection {
  createdAt: Date | null;
  createdBy: string;
  fileType: string;
  fileUrl: string;
  gsUrl: string;
  id: string;
  originalFilename: string;
  subCollectionReference: string;
}

export interface AssignmentSection {
  id: string;
  assignedTo: string;
  assignedBy: string;
  lastActivity: string;
  daysOpen: number;
}

export interface BusinessPurpose {
  isForRoster: boolean;
  isForCredentialing: boolean;
  isForLicensing: boolean;
  isForPayorEnrollment: boolean;
}

export type ProviderDetailSpecialtiesSection = {
  name: string;
  subSpecialties?: string[];
};

export type ProviderDetailSections =
  | ProviderDetailOverviewSection
  | ProviderDetailsLicenseSection
  | ProviderDetailDeaSection
  | ProviderDetailBoardCertificationsSection
  | ProviderDetailLicensureActionsSection
  | ProviderDetailSanctionsSection
  | ProviderDetailMalpracticeInsuranceSection
  | ProviderDetailPerfIndicatorSection
  | ProviderNatDataBankSection
  | ProviderAppVerificationsSection
  | ProviderEduTrainData
  | ProviderHospitalAffiliationData
  | ErrorsWarningsData
  | AssignmentSection
  | SupportingDocumentsSection;

export interface ProviderDetails {
  orgEntities?: any;
  dynamicFields?: any;
  firstName: string;
  middleName?: string;
  lastName: string;
  outreachReasons: string[];
  overview?: ProviderDetailOverviewSection[];
  assignment?: AssignmentSection[];
  stateLicenses?: ProviderDetailsLicenseSection[];
  dea?: ProviderDetailDeaSection[];
  boardCertifications?: ProviderDetailBoardCertificationsSection[];
  licensureActions?: ProviderDetailLicensureActionsSection[];
  sanctions?: ProviderDetailSanctionsSection[];
  malpracticeInsurance?: ProviderDetailMalpracticeInsuranceSection[];
  perfIndicator?: ProviderDetailPerfIndicatorSection[];
  nationalPractitionerDataBank?: ProviderNatDataBankSection[];
  training?: ProviderEduTrainData[];
  affiliation?: ProviderHospitalAffiliationData[];
  errorsWarnings?: ErrorsWarningsData[];
  credentialingTimeline?: CredentialingTimeline[];
  professionalIds?: ProfessionalID[];
  contactNumbers?: ContactNumber[];
  emailAddresses?: EmailAddress[];

  appVerification?: ProviderAppVerificationsSection[];
  supportingDocuments: SupportingDocumentsSection[];
  businessPurpose: BusinessPurpose;
  id: string;
  providerId: string;
  providerType: string;
  providerStatus: ProviderStatus;
  hasEditProvider: boolean;
  updatedBy: string;
  updatedAt?: Date;
  credentialingStatus?: CredentialingStatusType;
  isCredentialingWorkflowCompleted: boolean;
  fileType?: string;
  fileTypeAutoCalculatedValue?: string;
  fileTypeManuallyOverridden?: boolean;
  caqhData?: CaqhData[];
  verifications?: PipeLineVerifications;
  flagsObjects?: FlagObject[];
  flags?: Record<string, unknown>;
  currentFlags?: Record<string, Flag>;
  nppes?: nppesData;
  defaultFileTypeConfigOverrides?: FileTypeConfigOverridesType;
  practitionerSpecialties?: Specialties[] | null;
  practitionerPrimarySpecialty?: Specialties | null;
  practitionerHsdSpecialty?: HsdSpecialty | null;
  caqhLastAttestationDate?: string;
  caqhLastAttestationDateUpdatedAt?: Date;
  caqhLastAttestationDateUpdatedBy?: string;
  caqhLastAttestationDateUpdatedByFullname?: string;
}

export const ProviderDetailSectionNames = {
  deaData: 'dea-data',
  stateLicenses: 'state-licenses',
  licensureActions: 'licensure-actions',
  sanctions: 'sanctions',
  boardCertifications: 'board-certifications',
  liabilityInsurances: 'malpractice-insurances',
  performanceData: 'performance-data',
  nationalPractitionerDataBank: 'npdb-data',
  appVerification: 'application-verifications',
  training: 'education-trainings',
  supportingDocuments: 'supporting-documents',
} as const;

export const WorkflowTypes = {
  credentialing: 'CREDENTIALING',
  monitoring: 'MONITORING',
  licensing: 'LICENSING',
  payor_enrollment: 'PAYOR_ENROLLMENT',
} as const;

export const ProviderWorkflowTypes = {
  ...WorkflowTypes,
  payorEnrollment: 'payorEnrollment',
} as const;

export interface UpdateProviderSubcollectionPayload {
  providerId: string;
  section: (typeof ProviderDetailSectionNames)[keyof typeof ProviderDetailSectionNames];
  data: Partial<ProviderSectionUpdatePayload>[];
  actionSource?: 'CREDENTIALING' | 'MONITORING';
}
export interface TerminateProviderPayload {
  providerIds: string[];
  filters: ProviderFormData;
  reason: string;
}

export interface UnterminateProviderPayload {
  providerIds: string[];
  reason: string;
  filters: ProviderFormData;
}

export type ProviderOverviewUpdatePayload = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | null;
  primaryEmail: string;
  npi: string;
  providerType: string;
  caqhProviderId: string;
  credentialingStatus?: string;
  reason?: string;
};

export interface UpdateProviderProfilePayload {
  providerId: string;
  data:
    | Partial<ProviderOverviewUpdatePayload>
    | { contactNumbers: ContactNumber[] }
    | { emailAddresses: EmailAddress[] };
}

export interface TerminateProviderPayload {
  providerIds: string[];
  reason: string;
}

export interface PipeLineVerifications {
  npiValidated?: boolean;
  caqhRostered?: boolean;
  npdbEnrolled?: boolean;
}

export interface FetchProviderListApiResponseItem {
  npi: string;
  allProviderStates: string;
  firstName: string;
  providerType: string;
  providerStatus: 'New' | 'Active' | 'Denied' | 'In Progress' | 'Terminated';
  createdAt: ApiResponseDateTime;
  credentialingStatus?: string;
  credentialingDate?: ApiResponseDateTime;
  updatedAt: ApiResponseDateTime;
  middleName: string;
  caqhProviderId: string;
  id: string;
  lastFetchDate: ApiResponseDateTime;
  createdBy: string;
  lastName: string;
  fileType: string;
  providerId: string;
  editProviderId: string;
  assignedStates: Array<string>;
  states: Array<string>;
  isFlagged?: boolean;
  credentialingWorkflowTimeline: CredentialingTimelineFromApi;
  currentFlags?: Record<string, FlagApiResponse>;
  inProgressLicensingWorkflows?: number;
  submittedLicensingWorkflows?: number;
  completedLicensingWorkflows?: number;
  organizationName?: string;
  verifications?: PipeLineVerifications;
  organizationId?: string;
}

export interface ProviderListItem {
  npi: string;
  firstName: string;
  providerType: string;
  providerStatus: 'New' | 'Active' | 'Denied' | 'In Progress' | 'Terminated';
  createdAt?: Date; //this should not be optional, but the firestore to date transformer can return undefined for now

  credentialingStatus?: string;
  credentialingDate?: Date;

  updatedAt?: Date; //this should not be optional, but the firestore to date transformer can return undefined for now

  middleName: string;
  caqhProviderId: string;
  id: string;
  lastFetchDate?: Date;
  createdBy: string;
  lastName: string;
  fileType: string;
  editProviderId: string;
  providerId: string;
  assignedStates: string[];
  allProviderStates: string[];

  states: string[];
  isFlagged?: boolean;
  credentialingWorkflowTimeline: CredentialingTimeline;
  currentFlags?: Record<string, Flag>;

  applicationsInProgress?: number;
  statesInProgress?: string;
  inProgressLicensingWorkflows?: number;
  submittedLicensingWorkflows?: number;
  completedLicensingWorkflows?: number;
}

export interface FetchProviderListApiResponse {
  data: FetchProviderListApiResponseItem[];
  count: number;
}

export interface FetchProviderSubCollectionReportItem {
  reportType: ProviderSubCollectionReportReportType;
  organizationId: string;
  createdAt: ApiResponseDateTime;
  createdByName?: string;
  createdBy: string;
  subCollectionDocumentId: string;
  id: string;
  originalFilename: string;
  fileUrl?: string;
  orgEntityName: string;
  generationErrors?: string[];
}

export enum ProviderSubCollectionReportReportType {
  PSV = 'psv',
  NPDB_DATA = 'npdb-data',
  STATE_LICENSES = 'state-licenses',
  BOARD_CERTIFICATIONS = 'board-certifications',
  MALPRACTICE_INSURANCES = 'malpractice-insurances',
  DEA_DATA = 'dea-data',
  HOSPITAL_AFFILIATION = 'hospital-affiliation',
  EDUCATION_AND_TRAININGS = 'education-trainings',
}

export interface FetchProviderListResponse {
  data: ProviderListItem[];
  count: number;
}

export interface EditProviderDetailsPayload {
  providerId: string;
  firstName: string;
  lastName: string;
  providerType: string;
  caqhProviderId: string;
  reason: string;
}

export interface ProviderFileData {
  file: File;
  uploadedFileColumns: string[];
  uploadedFileRows: string[];
  columnMatchMatrix: {
    providerLastName: string;
    providerFirstName: string;
    providerMiddleName: string;
    providerDegree: string;
    providerNpi: string;
    allProviderStates: string;
    providerCaqh: string;
    providerId: string;
  };
}

export interface SupportingDocumentUploadPayload {
  file: File;
  fileType: string;
  subCollectionDocumentId?: string;
  subCollectionName?: string;
}
export interface SupportingDocumentUploadApiResponse {
  id: string;
}

export const PIPELINE_EXECUTION_TYPE = {
  CREDENTIALING: 'credentialing',
  RECREDENTIALING: 'recredentialing',
  MONITORING: 'monitoring',
} as const;

export type PipelineExecutionType =
  | typeof PIPELINE_EXECUTION_TYPE.CREDENTIALING
  | typeof PIPELINE_EXECUTION_TYPE.RECREDENTIALING
  | typeof PIPELINE_EXECUTION_TYPE.MONITORING;

export type PipelineSection =
  | 'application-verifications'
  | 'provider-details'
  | 'board-certifications'
  | 'caqh-data'
  | 'licensure-actions'
  | 'npdb-data'
  | 'state-licenses'
  | 'sanctions'
  | 'malpractice-insurance'
  | 'dea-data'
  | 'education-trainings'
  | 'supporting-documents'
  | 'deceased-provider';

export type ProviderSpecialtyResponse = {
  id: string;
  name: string;
  searchName: string;
  specialtyNuccTaxonomyCode?: string;
  code?: string;
  section: string;
  displayName?: string;
};

export type ProviderSubSpecialtyResponse = {
  id: string;
  name: string;
  searchName: string;
  specialtyNuccTaxonomyCode: string;
  topLevelName: string;
  section: string;
  displayName?: string;
};

export type FetchProviderSpecialtiesResponse = {
  data: ProviderSpecialtyResponse[];
  total: number;
};

export type FetchProviderSubSpecialtiesResponse = {
  data: ProviderSubSpecialtyResponse[];
  total: number;
};
